.react-bootstrap-table th[data-row-selection] {
    min-width: 40px;
    width: 40px;
}

.react-bootstrap-table td > .selection-input-4, .react-bootstrap-table th > .selection-input-4 {
    margin: 0;
}

.table thead th {
    vertical-align: middle !important;
}