.carousel-control-next, .carousel-control-prev {
    width: 8% !important;
}

.carousel-control-next-icon {
    border-radius: 50%;
    padding: 2px
}

.carousel-control-prev-icon {
    border-radius: 50%;
    padding: 2px
}

ol.carousel-indicators li {
    width: 10px;
    height: 10px;
    border: 1px solid #5D5D5D;
    border-radius: 20px;
}

.carousel-indicators .active {
    background: #5D5D5D
}

.export-csv-button {
    margin-top: -100px;
    z-index: 99;
    position: relative;
}

.form-check-input {
    width: 30px;
}